:root{
  --app-theme-color: #0284FF;
  --app-theme-color-light: #bebdf8;
  --app-theme-color-light2: #ffc9cb;
}

html{scroll-behavior:smooth}

.input-box[placeholder]:empty:before {
  content: attr(placeholder);
  color: #555; 
}


/* EA4C89 */
.bootbox.modal > .modal-dialog {
  z-index: 1050 !important;
}
/* 833fdc */
ngx-colors .app-color-picker .preview .circle  {
    height: 25px !important;
    width: 25px !important;
}


.profile-button:hover{
    background: #fff !important;
}

.bg-notion{
    background: #191919;
}

.container {
    max-width: 1440px !important;
}

a {
  text-decoration: none !important;
}

.border-block{
border:solid 1px var(--app-theme-color-light2) !important;
font-weight: 600 !important;
}






.dummy-card {
    background-color: #bbe1fa;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

mwlResizable {
    box-sizing: border-box;
  }
  
  .rectangle {
    position: relative;
    top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 150px;
    margin: auto;
    border-radius: 30px;
    font-size: 25px;
    font-weight: bold;
  }
  .resize-handle-top,
  .resize-handle-bottom {
    position: absolute;
    height: 5px;
    cursor: row-resize;
    width: 100%;
  }
  .resize-handle-top {
    top: 0;
  }
  .resize-handle-bottom {
    bottom: 0;
  }
  .resize-handle-left,
  .resize-handle-right {
    position: absolute;
    height: 100%;
    cursor: col-resize;
    width: 5px;
  }
  .resize-handle-left {
    left: 0;
  }
  .resize-handle-right {
    right: 0;
  }

  .amplify-button--primary{
    background-color: #EC4899 !important;
    background: #EC4899 !important;
    color:white !important;
}

.amplify-tabs-item[data-state=active]{
  border-color: #EC4899 !important;
  color: #000 !important;
}


[data-amplify-authenticator] [data-amplify-router] {
  
  border: 0;
  border-radius: 20px;
  overflow: hidden;
}


.toggle-bg:after {
  content: '';
  @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
}
input:checked + .toggle-bg:after {
  transform: translateX(100%);
  @apply border-white;
}
input:checked + .toggle-bg {
  @apply bg-pink-500 border-pink-500;
}



.grad{
  background-image:-webkit-linear-gradient(45deg, rgb(248, 28, 224) 0%, rgb(138, 70, 255) 50%) !important;
  /* -webkit-background-clip: text !important; */
}


.black{
  color:#000 !important;
}

.slider {


  -webkit-appearance: none;
  height: 8px;
  border-radius: 4px;

  background-color: rgb(200, 200, 200);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  background-color: #EC4999;
  overflow: visible;
  cursor: pointer;
}

.slidecontainer {
  transform: translateY(-10px);
}

.btn{
  box-shadow: none !important;
}



h1 {
  font-size: 36px; /* You can adjust the font size as needed */
}

h2 {
  font-size: 30px; /* You can adjust the font size as needed */
}

h3 {
  font-size: 24px; /* You can adjust the font size as needed */
  font-weight: 600 !important;
}

h4 {
  font-size: 20px; /* You can adjust the font size as needed */
}

h5 {
  font-size: 18px; /* You can adjust the font size as needed */
}

h6 {
  font-size: 16px; /* You can adjust the font size as needed */
}


progress[value]::-webkit-progress-value {
  /* background: var(--app-theme-color); */
  background: #000;
}

.fa-gradient {
	background: -webkit-gradient(linear, left top, left bottom, from(rgb(216, 90, 204)), to(#c7be16));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}


.bg-tree-selected{
  background: #1b2230;
  border-right:solid 0px #FACC13;
}

body{
  font-family: 'Poppins', sans-serif !important;
  background: rgb(246,249,255) !important;
background: linear-gradient(180deg, rgba(246,249,255,1) 21%, rgba(255,255,255,1) 100%);
}

.selected_item{
  background: #E4E1EF;
}


.ce-toolbar--opened {
  display: block;
  width:fit-content;
}


.ce-toolbar {
width:fit-content;
}

.ce-block__content {
max-width: unset !important;
}

.modal-box{
  top: 100px;
    position: absolute;
}

.common {

}

label{
  margin:0px !important;
}

.steps .step-primary + .step-primary:before, .steps .step-primary:after{
  background: #1D4ED8 !important;
}

.dropdown-toggle {
  background: #fff;
  border: 0;
  text-transform: unset;
  font-weight: 400;
  color:#000;
}

.chat-bubble {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.divider{
  
  height: 1px;
  background: #E4E1EF;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
}

.cdx-block {
  padding: 0.4em 0;
  white-space: break-spaces !important;
}

button:focus{
  outline: none !important;
}

.subsection-action {
  display: none;
}

.subsection-item:hover .subsection-action {
  display: block;
}

.subsection-drag-handle {
  display: none;
}

.subsection-item:hover .subsection-drag-handle {
  display: block;
}

.subsection-add {
  border: 2px dashed #111;
  border-radius: 5px;
  text-align: center;
}


.topic-action {
  display: none;
}

.topic-item:hover .topic-action {
  display: block;
}

.topic-drag-handle {
  display: none;
}

.topic-item:hover .topic-drag-handle{
  display: block;
}

.topic-add {
  border: 0px dashed #111;
  border-radius: 5px;
  text-align: center;
}



.bg-gradient-cyan {
  background-image: linear-gradient(90deg,#6cd5f6,#f89d5c 50%,#5b6af0);
  /* background-image: linear-gradient(90deg,#797979,#797979 50%,#797979); */
  /* border:solid 1px #f2f2f2; */
  /* background-color: #f2f2f2; */
}

.kntooltip {
   visibility: hidden;
    position: absolute;
}

.has-ntooltip:hover .kntooltip {
  visibility: visible;
  z-index: 100;
}

.breadcrumb-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.dropdown-toggle::after{
display: none !important;
}

#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.cdx-list__item {
  padding: 5.5px 0 5.5px 3px;
  line-height: 1.6em;
  margin: 0;
  padding-top: 0;
}

.cdx-simple-image__picture > img {
  min-width: 300px;
  margin: auto;
}
.cdx-simple-image .cdx-input{
  padding: 0;
    border: 0;
    margin: auto;
    text-align: center;
    font-size: 12px;
}

::-webkit-scrollbar {
  width: 0;  
}
.bg-primary{
  background: #5628ff !important;
  color:white !important;
}

.relative:hover .play-button {
  display: block;
}

.relative:hover .pause-button {
  display: none;
}

.ce-block__content {
  white-space: break-spaces;
}

.menu-link:hover{
  color:blue !important;
}

ngx-extended-pdf-viewer #toolbarViewer {
  min-height: 32px;
  padding:10px 10px 10px 18px !important;
}

.chat-container {
  flex-grow: 1;
      overflow-y: auto;
}

.r_res:hover > .r_del{
  display: block;
}

.subtab{
  background: inherit;
   border-bottom:solid 2px var(--app-theme-color) !important;
}

.custom-link  a {
  color: #045fb4;
  text-decoration: underline !important;
}

.shadow-prop{
  box-shadow: 0px 4px 16px 0px rgba(79,114,205,.1);
}

.code-block {
  user-select: all;
}

.code-div {
  background-color: #1C1B19;
  color: #fff;
  border-radius: 10px;
  padding: 15px !important;
  overflow: scroll;

}

.code-div pre {
  color: #E4D2B0 !important;
  display: block;
    overflow-x: auto;
    padding: 1rem 1rem 1.5rem;
    background: rgb(28, 27, 25);
    color: rgb(252, 232, 195);
    max-width: 100%;
}

.send_box {
  resize: none; /* Disable manual resizing */
  overflow-y: hidden; /* Hide vertical scrollbar */
 outline: none !important;
  max-height: 300px; /* Set the maximum height, adjust as needed */
  width: 100%; /* Set the width to fill its container */
  font-size: 16px; /* Set the font size, adjust as needed */
  padding: 10px; /* Add some padding for better appearance */
  box-sizing: border-box; /* Make sure padding and border don't affect the total height */
  border:0 !important;
  --tw-ring-color: #fff !important;
}

.send_box:focus {
  outline: none !important;
  border:0 !important;
  --tw-ring-color: #fff !important;
}

textarea:focus{
  outline: none !important;
  --tw-ring-color: #fff !important;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

a{
  cursor:  pointer !important;
}


#chat-box{
 
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);

}

#chatbox-header{

  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);

}




/* -------------------------- ntooltip -------------------------- */

.ntooltip {
	
	position: relative;
	margin: 15px;
	text-align: center;
}

.ntooltip::after {
	background-color: #333;
	border-radius: 10px;
	color: #fff;
	display: none;
	padding: 10px 15px;
	position: absolute;
	text-align: center;
	z-index: 999;
  font-size: 16px;
}

.ntooltip::before {
	background-color: #333;
	content: ' ';
	display: none;
	position: absolute;
	width: fit-content;
	height: fit-content;
	z-index: 999;
}

.ntooltip:hover::after {
	display: block;
}

.ntooltip:hover::before {
	display: block;
}

.ntooltip.top::after {
  /* get content from attr data */
	content: attr(data-content);
	top: 0;
	left: 50%;
	transform: translate(-50%, calc(-100% - 10px));
}

.ntooltip.top::before {
	top: 0;
	left: 50%;
	transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.ntooltip.bottom::after {
	content: attr(data-content);;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, calc(100% + 10px));
}

.ntooltip.bottom::before {
	bottom: 0;
	left: 50%;
	transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.ntooltip.right::after {
	content: attr(data-content);;
	top: 0;
	right: 0;
	transform: translateX(calc(100% + 10px));
}

.ntooltip.right::before {
	top: 50%;
	right: 0;
	transform: translate(calc(100% + 5px), -50%) rotate(45deg);
}

.ntooltip.left::after {
	content: attr(data-content);;
	top: 0;
	left: 0;
	transform: translateX(calc(-100% - 10px));
}

.ntooltip.left::before {
	top: 50%;
	left: 0;
	transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
}

.bootbox.modal{
  display: block !important;
  visibility: unset;
  z-index:999999999999 !important;
  opacity: unset !important;
}

.bootbox > .modal-dialog > .modal-content > .modal-header{
  display: unset !important;
}

.bootbox > .modal-dialog > .modal-content > .modal-footer > button{
  background: var(--app-theme-color) !important;
}


.bg-blue-300	
{
  background-color: #bae6fd;
}

.bg-cyan-300  
{
  background-color: #a5f3fc
}

.bg-green-300	
{
  background-color: #a7f3d0
}

.bg-rose-300	
{
  background-color: #fbcfe8
}

.bg-orange-300	
{
  background-color: #fed7aa
}


.bg-purple-300	
{
  background-color: #d8b4fe
}


.bg-yellow-300	
{
  background-color: #fef08a
}

